import axios from 'axios';

const API_BASE_URL = 'http://localhost:3001'; // Überprüfen Sie, ob dieser Port korrekt ist

export const fetchBookings = async () => {
  try {
    console.log('Versuche Buchungen abzurufen...');
    const response = await axios.get(`${API_BASE_URL}/api/bookings`);
    console.log('Serverantwort:', response.data);
    return response.data;
  } catch (error) {
    console.error('Fehler beim Abrufen der Buchungen:', error.response ? error.response.data : error.message);
    throw error;
  }
};